import Vue from 'vue';
import {createApp} from 'vue';
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css/'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import VueUeditorWrap from 'vue-ueditor-wrap';
import dayjs from 'dayjs';
createApp(App)
    .use(ElementPlus,{locale})
    .use(router)
    .use(dayjs)
    .use(store)
    .use(VueUeditorWrap).mount('#app')
router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('Token');
    let path = to.path;
    if (path == '/Login') {
        next();
        return;
    }      //以防无限循环
    if (token) {
        next();
    } else {
        next({
            path: '/Login'
        })
    }
})