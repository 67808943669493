//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 * post==> data: obj
 * get==>params: obj
 *
 */
/*菜单接口---------------------------*/
//获取菜单列表接口
export function getmenuData(obj) {
    return request({
        url: '/api/admin/menu',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增菜单接口
export function postaddmenu(obj,url) {
    return request({
        url: '/api/admin/menu/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑菜单接口
export function posteditmenu(obj,url) {
    return request({
        url: '/api/admin/menu/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除菜单接口
export function postdelmenu(obj,url) {
    return request({
        url: '/api/admin/menu/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

/*管理员接口---------------------------*/
//获取管理员列表接口
export function getregisterData(obj) {
    return request({
        url: '/api/admin/user',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增管理员接口
export function postaddregister(obj,url) {
    return request({
        url: '/api/admin/register',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑管理员接口
export function posteditregister(obj,url) {
    return request({
        url: '/api/admin/user/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除管理员接口
export function postdelregister(obj,url) {
    return request({
        url: '/api/admin/user/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

/*角色接口---------------------------*/
//获取角色列表接口
export function getroleData(obj) {
    return request({
        url: '/api/admin/role',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增角色接口
export function postaddrole(obj,url) {
    return request({
        url: '/api/admin/role/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑角色接口
export function posteditrole(obj,url) {
    return request({
        url: '/api/admin/role/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除角色接口
export function postdelrole(obj,url) {
    return request({
        url: '/api/admin/role/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

//管理员地区列表
export function AdminaddList(obj) {
    return request({
        url: `api/admin/userArea?page=${obj.page}&limit=${obj.limit}&user_id=${obj.user_idd}`,      // url = base url + request url
        method: 'get'
    })
}
// 新增地区
export function addListdata(obj) {
    return request({
        url: `api/admin/userArea/add`,      // url = base url + request url
        method: 'post',
        data:{user_id:obj.user_idd,area_id:obj.id}
    })
}
// 删除地区
export function delListdata(idd) {
    return request({
        url: `api/admin/userArea/del`,      // url = base url + request url
        method: 'post',
        data:{id:idd}
    })
}
