//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*广告接口---------------------------*/
//GET方法
export function get(obj,url) {
    return request({
        url: url,      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//POST
export function post(obj,url) {
    return request({
        url: url,      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
export function blob(obj,url) {
    return request({
        url: url,      // url = base url + request url
        method: 'get',
        params: obj,
        responseType: 'blob',//设置响应数据类型
        //----------------->>>区别
    })
}
// 城市地区列表
export function addDq() {
    return request({
        url: "/api/admin/userArea/myList",      // url = base url + request url
        method: 'get',
    })
}
// 城市地区列表根据用户权限展示产品
export function AdminaddDq(res) {
    return request({
        url: "/api/admin/area/areaCity",      // url = base url + request url
        method: 'get',
        params:res
    })
}