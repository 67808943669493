
<template>
  <el-container style="height: calc(100vh)">
    <el-aside
      width="210px"
      style="
        box-shadow: 5px 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        background-color: #545c64;
        height: calc(100vh);
      "
    >
      <div style="padding: 10px">
        <el-image
          style="width: auto; height: 40px"
          :src="url"
          fit="cover"
        ></el-image>
      </div>
      <el-scrollbar style="height: calc(100vh - 64px)">
        <el-menu
          :router="true"
          @select="menuData"
          height="40px"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          :default-active="nowXuan"
          class="el-menu-vertical-demo"
        >
          <div v-for="(item, index) in submenuList" :key="index">
            <!-- 一级菜单（没有任何子级菜单）-->
            <el-menu-item :index="item.component" v-if="!item.children">
              <i :class="item.icon_class"></i>
              {{ item.name }}
            </el-menu-item>
            <!-- 一级菜单（有子级菜单）-->
            <el-sub-menu :index="item.component" v-else>
              <template #title>
                <i :class="item.icon_class"></i>
                {{ item.name }}
              </template>
              <!-- 遍历二级菜单容器 -->
              <div v-for="(item, index) in item.children" :key="index">
                <!-- 判断二级菜单（没有三级菜单）-->
                <el-menu-item :index="item.component" v-if="!item.children">
                  <i :class="item.icon_class"></i>
                  {{ item.name }}
                </el-menu-item>
                <!-- 判断二级菜单（有三级菜单）-->
                <el-sub-menu :index="item.component" v-if="item.children">
                  <template #title>
                    <i :class="item.icon_class"></i>
                    {{ item.name }}
                  </template>
                  <el-menu-item
                    :index="item.component"
                    v-for="(item, index) in item.menuList"
                    :key="index"
                  >
                    <i :class="item.icon_class"></i>
                    {{ item.name }}
                  </el-menu-item>
                </el-sub-menu>
              </div>
            </el-sub-menu>
          </div>
        </el-menu>
      </el-scrollbar>
    </el-aside>
    <el-container>
      <el-header
        style="font-size: 12px; display: flex; justify-content: space-between"
      >
        <div style="padding: 10px">
          <div style="margin-top: 20px">
            <el-breadcrumb>
              <el-breadcrumb-item :to="{ path: '/home/about' }"
                >首页</el-breadcrumb-item
              >
              <el-breadcrumb-item v-if="breadobj.title != '首页'">{{
                breadobj.title
              }}</el-breadcrumb-item>
              <!--              <el-breadcrumb-item v-for="(item,i) in bread" :key="i" :to="{ path:item.path}">{{item.meta.title}}</el-breadcrumb-item>-->
            </el-breadcrumb>
          </div>
        </div>
        <div style="margin-right: 20px">
          <el-dropdown @command="handleCommand">
            <i
              class="el-icon-setting"
              style="
                font-size: 18px;
                margin-right: 15px;
                color: #4c4c4c;
                font-weight: 600;
              "
            ></i>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="退出登录">退出登录</el-dropdown-item>
                <!--              <el-dropdown-item command="修改密码">修改密码</el-dropdown-item>-->
                <!--              <el-dropdown-item>删除</el-dropdown-item>-->
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <span style="color: #4c4c4c; font-weight: 600; font-size: 16px">{{
            user
          }}</span>
        </div>
      </el-header>
      <el-main
        style="
          height: calc(100vh - 60px);
          background-color: rgba(240, 242, 245, 0.26);
        "
      >
        <el-card shadow="hover">
          <router-view></router-view>
        </el-card>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import router from "@/router";
import { getMenu } from "@/api/common"; //本页面接口
import axioss from "axios";
import a from "../../public/image/fangdindin.png";
import * as axios from "@/api/menuApi";
import { get, post, getMusic } from "@/api/Api";
import { ElNotification } from "element-plus";

export default {
  name: "home",
  components: {},
  data() {
    return {
      bread: [],
      breadobj: {
        title: "",
      },
      user: "",
      url: a,
      nowXuan: "",
      submenuList: [
        // {
        //   icon: "el-icon-data-line",
        //   id: 1,
        //   title: "首页",
        //   url: "/home/about",
        // },
        // {
        //   icon: "el-icon-data-line",
        //   id: 6,
        //   title: "产品",
        //   url: "/home/product",
        //   menuList:[
        //     {
        //       icon: "el-icon-data-line",
        //       id: 7,
        //       title: "产品",
        //       url: "/home/product",
        //     },
        //     {
        //       icon: "el-icon-data-line",
        //       id: 8,
        //       title: "产品标签",
        //       url: "/Home/tag",
        //     },
        //     {
        //       icon: "el-icon-data-line",
        //       id: 9,
        //       title: "产品常见问题",
        //       url: "/Home/question",
        //     },
        //     {
        //       icon: "el-icon-data-line",
        //       id: 10,
        //       title: "产品拓展详情",
        //       url: "/Home/content",
        //     },
        //     {
        //       icon: "el-icon-data-line",
        //       id: 11,
        //       title: "产品分类",
        //       url: "/Home/classifica",
        //     },
        //   ]
        // },{
        //   icon: "el-icon-data-line",
        //   id: 6,
        //   title: "管理员管理",
        //   url: "/home/Menus",
        //   menuList:[
        //     {
        //       icon: "el-icon-data-line",
        //       id: 2,
        //       title: "菜单",
        //       url: "/home/Menu",
        //     },
        //     {
        //       icon: "el-icon-data-line",
        //       id: 21,
        //       title: "角色管理",
        //       url: "/home/role",
        //     },
        //     {
        //       icon: "el-icon-data-line",
        //       id: 22,
        //       title: "管理员",
        //       url: "/home/register",
        //     },
        //   ]
        // },
        //
        // {
        //   icon: "el-icon-data-line",
        //   id: 3,
        //   title: "地区",
        //   url: "/home/Area",
        // },
        // {
        //   icon: "el-icon-data-line",
        //   id: 4,
        //   title: "品牌",
        //   url: "/home/brand",
        // },{
        //   icon: "el-icon-data-line",
        //   id: 5,
        //   title: "收费项",
        //   url: "/home/charge",
        // }, {
        //   icon: "el-icon-data-line",
        //   id: 12,
        //   title: "优惠券管理",
        //   url: "/home/coupon",
        // },{
        //   icon: "el-icon-data-line",
        //   id: 121,
        //   title: "用户管理",
        //   url: "/home/member",
        // },{
        //   icon: "el-icon-data-line",
        //   id: 122,
        //   title: "师傅管理",
        //   url: "/home/master",
        // },{
        //   icon: "el-icon-data-line",
        //   id: 131,
        //   title: "前台管理",
        //   url: "/home/members",
        //   menuList:[
        //     {
        //       icon: "el-icon-data-line",
        //       id: 121,
        //       title: "用户首页模块",
        //       url: "/home/member_home",
        //     },{
        //       icon: "el-icon-data-line",
        //       id: 122,
        //       title: "师傅首页模块",
        //       url: "/home/master_home",
        //     },{
        //       icon: "el-icon-data-line",
        //       id: 111,
        //       title: "前台产品分类",
        //       url: "/Home/backclass",
        //     },{
        //       icon: "el-icon-data-line",
        //       id: 14,
        //       title: "反馈类型",
        //       url: "/home/feedback",
        //     },{
        //       icon: "el-icon-data-line",
        //       id: 15,
        //       title: "投诉类型",
        //       url: "/home/complaint",
        //     },{
        //       icon: "el-icon-data-line",
        //       id: 17,
        //       title: "服务协议",
        //       url: "/home/agreement",
        //     },{
        //       icon: "el-icon-data-line",
        //       id: 16,
        //       title: "退款类型",
        //       url: "/home/refundtype",
        //     },{
        //       icon: "el-icon-data-line",
        //       id: 17,
        //       title: "退款原因",
        //       url: "/home/refundreason",
        //     },{
        //       icon: "el-icon-data-line",
        //       id: 18,
        //       title: "关于我们",
        //       url: "/home/about_us",
        //     },
        //   ]
        // },{
        //   icon: "el-icon-data-line",
        //   id: 112,
        //   title: "订单管理",
        //   url: "/Home/orders",
        //   menuList:[
        //     {
        //       icon: "el-icon-data-line",
        //       id: 112,
        //       title: "订单",
        //       url: "/Home/order",
        //     },
        //     {
        //       icon: "el-icon-data-line",
        //       id: 8,
        //       title: "退款列表",
        //       url: "/Home/order/refund",
        //     },
        //     {
        //       icon: "el-icon-data-line",
        //       id: 9,
        //       title: "投诉列表",
        //       url: "/Home/order/complain",
        //     },
        //   ]
        // }, {
        //   icon: "el-icon-data-line",
        //   id: 116,
        //   title: "文章管理",
        //   url: "/home/article",
        //   menuList:[
        //     {
        //       icon: "el-icon-data-line",
        //       id: 122,
        //       title: "文章管理",
        //       url: "/home/article",
        //     },
        //     {
        //       icon: "el-icon-data-line",
        //       id: 122,
        //       title: "文章分类",
        //       url: "/home/category",
        //     },
        //     {
        //       icon: "el-icon-data-line",
        //       id: 122,
        //       title: "文章标签",
        //       url: "/home/articletag",
        //     },
        //
        //   ]
        // },{
        //   icon: "el-icon-data-line",
        //   id: 117,
        //   title: "扣费规则",
        //   url: "/home/deduction",
        //   menuList:[
        //     {
        //       icon: "el-icon-data-line",
        //       id: 1171,
        //       title: "扣费管理",
        //       url: "/home/deduction",
        //     },
        //     {
        //       icon: "el-icon-data-line",
        //       id: 1172,
        //       title: "扣费编码",
        //       url: "/home/code",
        //     },
        //
        //   ]
        // },{
        //   icon: "el-icon-data-line",
        //   id: 131,
        //   title: "师傅技能分类",
        //   url: "/home/master_skill",
        // },{
        //   icon: "el-icon-data-line",
        //   id: 13,
        //   title: "广告管理",
        //   url: "/home/advert",
        // },{
        //   icon: "el-icon-data-line",
        //   id: 17,
        //   title: "上门服务费",
        //   url: "/home/service_fee",
        // },{
        //   icon: "el-icon-data-line",
        //   id: 171,
        //   title: "押金管理",
        //   url: "/home/deposit",
        // },
      ],
    };
  },
  created() {
    console.log("路由数据", this.$route);
    this.breadobj.title = this.$route.meta.title;
    this.nowXuan = this.$route.path;
    this.onSerch();
    this.submenuList = JSON.parse(localStorage.getItem("menu"));
    this.newsFun();
  },
  mounted() {
    // let path = this.$route.path;
    // this.navConfig = [
    //   {index:'1',path:['/system/aa','/system/bb','/system/cc']},
    // ];
    // let thisNav = this.navConfig.find(item =>{
    //   return item.path.includes(path);
    // });
    // this.defaultOpeneds = [thisNav.index];
  },
  methods: {
    newsFun() {
      let that = this;
      setTimeout(() => {
        get("", "/api/admin/searchOrder")
          .then((res) => {
            if (res.code == 200 && res.data > 0) {
              ElNotification({
                title: "提示！",
                type: "success",
                duration: 600000,
                message: "您有(" + res.data + ")条新订单请注意查看!!",
                onClick() {
                  that.onorder();
                },
              });
              that.music();
              that.newsFun();
            } else {
              // that.music()
              that.newsFun();
              // ElNotification({
              //   title: '提示！',
              //   type: 'success',
              //   duration: 600000,
              //   message:"您有("+res.data+")条新订单请注意查看!!",
              //   onClick(){that.onorder()}
              // })
            }
          })
          .catch((err) => {
            that.newsFun();
            // ElNotification({
            //   title: '错误提示！',
            //   type: 'error',
            //   duration: 300000,
            //   message:"请求异常！！请联系管理员或刷新重试",
            // })
          });
      }, 20000);
    },
    music() {
      let str = "您有新订单啦";
      let url =
        "https://tts.baidu.com/text2audio?cuid=baike&lan=ZH&ctp=1&pdt=301&vol=9&rate=32&per=0&tex=" +
        encodeURI(str);
      let audio = new Audio(url);
      setTimeout(function () {
        audio.play();
      }, 1000);
    },
    onorder() {
      console.log("点了");
      router.push("/home/order");
    },
    menuData(index, indexPath, item, routeResult) {
      let that = this;
      setTimeout(function () {
        console.log(that.$route.meta.title);
        that.breadobj.title = that.$route.meta.title;
      }, 200);
    },
    // getRouter(){
    //   this.bread=this.$route.matched.filter((item) =>{
    //     return item.meta;
    //   })
    // },
    // sendPath(path){
    //   this.$refs.aside.active = path;
    // },
    onSerch() {
      this.user = JSON.parse(localStorage.getItem("user")).name;
    },
    handleCommand(val) {
      console.log(val);
      if (val == "退出登录") {
        localStorage.removeItem("Token");
        localStorage.removeItem("loglevel:webpack-dev-server");
        localStorage.removeItem("user");
        localStorage.removeItem("menu");
        this.$message.success("退出成功！");
        router.push("/login");
      }
    },
  },
  watch: {
    // $router:function (to,from) {
    //   this.getRouter();
    //   console.log(this.$route)
    //   this.sendPath((to.path))
    //   if(to.path=='/man'){
    //
    //   }
    // }
  },
};
</script>
<style lang="less" >
.el-table__header-wrapper {
  .el-checkbox__inner {
    display: none !important;
  }
}
.tabForm {
  margin: 5px 0;
  padding: 10px 20px 0 5px;
  border: 1px solid #e8e8e8;
}
.tab {
  margin: 5px 0;
  padding: 10px 20px;
  border: 1px solid #e8e8e8;
}
.el-upload {
  width: 98px !important;
  height: 98px !important;
}
.avatar-uploader-icon {
  line-height: 100px !important;
}
html,
body {
  margin: 0;
}
.el-table .el-table__cell {
  padding: 6px 0 !important;
}
.el-menu {
  width: 100% !important;
  border-right: none !important;
}
.el-sub-menu .el-menu-item {
  min-width: 150px !important;
}
.el-header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  color: var(--el-text-color-primary);
  line-height: 60px;
}

.el-aside {
  color: var(--el-text-color-primary);
}

.avatar-uploader .el-upload {
  width: 98px !important;
  height: 98px !important;
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload {
  width: 98px !important;
  height: 98px !important;
}
.el-checkbox {
  height: 32px;
}
/*消息提示*/

.el-notification.right {
  background-color: #e9a221c7 !important;
  height: 110px;
}
.el-icon-close:before {
  color: white !important;
  background-color: #c3c3c3;
  border-radius: 2px;
}
.el-notification__content p {
  cursor: pointer;
  font-size: 18px !important;
  color: white !important;
}
.el-notification__title {
  font-size: 20px !important;
  color: white !important;
}
/*消息提示*/
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 98px !important;
  height: 98px !important;
  line-height: 98px;
  text-align: center;
}
.avatar {
  width: 98px !important;
  height: 98px !important;
  display: block;
}
.el-notification.right {
  background-color: #c3e3f9;
}
</style>