//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
// (post)：
export function updateBook(obj) {
    return request({
        url: '/api/admin/login',      // url = base url + request url
        method: 'post',
        data: obj,    //----------------->>>区别
    })
}
//(get)：获取首页数据接口
export function getHomeData(obj) {
    return request({
        url: '/api/admin/statistics',      // url = base url + request url
        method: 'get',
        params: obj,    //----------------->>>区别
    })
}//(get)：获取上传地址接口
export function getUQIMGurl(obj) {
    return request({
        url: '/api/getUploadUrl',      // url = base url + request url
        method: 'get',
        params: obj,    //----------------->>>区别
    })
}
//(get)：产品分类树下拉
export function postCateTree(obj) {
    return request({
        url: '/api/admin/category/getCateTree',      // url = base url + request url
        method: 'post',
        data: obj,    //----------------->>>区别
    })
}//(get)：产品列表
export function postProduct(obj) {
    return request({
        url: '/api/admin/product',      // url = base url + request url
        method: 'get',
        params: obj,    //----------------->>>区别
    })
}
//(get)：品牌下拉
export function getbrandselect(obj) {
    return request({
        url: '/api/admin/brand?page=1&limit=200&w_type=base',      // url = base url + request url
        method: 'get',
        params: obj,    //----------------->>>区别
    })
}
/*品牌接口---------------------------*/
//(put)：图片上传接口
export function getIMG(obj,url,type) {
    return request({
        url: url,      // url = base url + request url
        method: 'put',
        data: obj,
        type:type
        //----------------->>>区别
    })
}
//(put)：获取城市下拉接口
export function getArea(obj) {
    return request({
        url: '/api/admin/area',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//(put)：获取城市下拉接口
export function getAreaTop(obj) {
    return request({
        url: '/api/admin/area/areaCity',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//(put)：获取收费项下拉接口
export function getCharge(obj) {
    return request({
        url: '/api/admin/charge?page=1&limit=400',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//(put)：获取上门服务费下拉接口
export function getBasic(obj) {
    return request({
        url: '/api/admin/basic/getBasicList',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//(put)：获取菜单下拉接口
export function getMenu(obj) {
    return request({
        url: '/api/admin/getRule',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
