import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router';
import Home from '../views/Home.vue'

const routes = [
  {
    meta: { title: '登录' },
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    meta: { title: 'Home' },
    path: '/Home',
    name: 'Home',
    component: Home,
    children:[
      {
        path: '/Home/about',
        name: 'About',
        meta:{
          title: '首页',
          requireLogin:true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        meta: { title: 'User' },
        path: '/Home/user',
        name: 'User',
        component: () => import('../views/User.vue')
      },
      {
        meta: { title: '品牌管理' },
        path: '/Home/brand',
        name: 'Brand',
        component: () => import('../views/Brand.vue')
      },
      {
        meta: { title: '产品管理' },
        path: '/Home/product',
        name: 'Product',
        component: () => import('../views/Product.vue')
      },
      {
        meta: { title: '产品标签' },
        path: '/Home/tag',
        name: 'Tag',
        component: () => import('../views/productItem/Tag.vue')
      },
      {
        meta: { title: '产品常见问题' },
        path: '/Home/question',
        name: 'Question',
        component: () => import('../views/productItem/Question.vue')
      },
      {
        meta: { title: '产品拓展详情' },
        path: '/Home/content',
        name: 'Content',
        component: () => import('../views/productItem/Content.vue')
      },
      {
        meta: { title: '产品分类' },
        path: '/Home/classifica',
        name: 'classifica',
        component: () => import('../views/productItem/Classifica.vue')
      },
      {
        meta: { title: '收费项' },
        path: '/Home/charge',
        name: 'Charge',
        component: () => import('../views/subsidiary/Charge.vue')
      },
      {
        meta: { title: '地区管理' },
        path: '/Home/area',
        name: 'Area',
        component: () => import('../views/subsidiary/Area.vue')
      },
      {
        meta: { title: '优惠券管理' },
        path: '/Home/coupon',
        name: 'Coupon',
        component: () => import('../views/subsidiary/Coupon.vue')
      },
      {
        meta: { title: '广告管理' },
        path: '/Home/advert',
        name: 'Advert',
        component: () => import('../views/subsidiary/Advertisement.vue')
      },
      {
        meta: { title: '反馈类型' },
        path: '/Home/feedback',
        name: 'Feedback',
        component: () => import('../views/subsidiary/Feedback.vue')
      },
      {
        meta: { title: '反馈列表' },
        path: '/Home/front/feedback',
        name: 'feedback',
        component: () => import('../views/front/feedback.vue')
      },
      {
        meta: { title: '投诉类型' },
        path: '/Home/complaint',
        name: 'Complaint',
        component: () => import('../views/subsidiary/Complaint.vue')
      },
      {
        meta: { title: '证件类型' },
        path: '/Home/certificates',
        name: 'Certificates',
        component: () => import('../views/management/Certificates.vue')
      },
      {
        meta: { title: '退款类型' },
        path: '/Home/refundtype',
        name: 'Refundtype',
        component: () => import('../views/subsidiary/Refundtype.vue')
      },
      {
        meta: { title: '退款原因' },
        path: '/Home/refundreason',
        name: 'Refundreason',
        component: () => import('../views/subsidiary/Refundreason.vue')
      },
      {
        meta: { title: '前台产品分类' },
        path: '/Home/backclass',
        name: 'Backclass',
        component: () => import('../views/Backclass.vue')
      },
      {
        meta: { title: '用户首页模块' },
        path: '/Home/member_home',
        name: 'Memberhome',
        component: () => import('../views/Memberhome.vue')
      },
      {
        meta: { title: '师傅首页模块' },
        path: '/Home/master_home',
        name: 'Masterhome',
        component: () => import('../views/Masterhome.vue')
      },
      {
        meta: { title: '文章列表' },
        path: '/Home/article',
        name: 'Article',
        component: () => import('../views/Article.vue')
      },
      {
        meta: { title: '文章标签' },
        path: '/Home/articletag',
        name: 'Articletag',
        component: () => import('../views/articleItem/Articletag.vue')
      },
      {
        meta: { title: '文章分类' },
        path: '/Home/category',
        name: 'Category',
        component: () => import('../views/articleItem/Category.vue')
      },
      {
        meta: { title: '订单管理' },
        path: '/Home/order',
        name: 'Order',
        component: () => import('../views/Order.vue')
      },
      {
        meta: { title: '投诉列表' },
        path: '/Home/order/complain',
        name: 'Complain',
        component: () => import('../views/orderItem/Complain.vue')
      },
      {
        meta: { title: '退款列表' },
        path: '/Home/order/refund',
        name: 'Refund',
        component: () => import('../views/orderItem/Refund.vue')
      },
      {
        meta: { title: '用户管理' },
        path: '/Home/member',
        name: 'Member',
        component: () => import('../views/Memberworker.vue')
      },
      {
        meta: { title: '分销等级' },
        path: '/Home/grade',
        name: 'Grade',
        component: () => import('../views/userSestem/Grade')
      },
      {
        meta: { title: '分销员'},
        path: '/Home/distribution',
        name: 'Distribution',
        component: () => import('../views/userSestem/Distribution')
      },
      {
        meta: { title: '批量归属修改'},
        path: '/Home/userSestem/refar',
        name: 'refar',
        component: () => import('../views/userSestem/refar')
      },
      {
        meta: { title: '提现规则' },
        path: '/Home/withdraw',
        name: 'Withdraw',
        component: () => import('../views/userSestem/Withdraw')
      },
      {
        meta: { title: '佣金规则' },
        path: '/Home/commission',
        name: 'Commission',
        component: () => import('../views/userSestem/Commission')
      },
      {
        meta: { title: '用户注销' },
        path: '/Home/logout',
        name: 'Logout',
        component: () => import('../views/userSestem/Logout')
      },
      {
        meta: { title: '师傅注销' },
        path: '/Home/masterLogout',
        name: 'MasterLogout',
        component: () => import('../views/userSestem/MasterLogout')
      },
      {
        meta: { title: '师傅证书' },
        path: '/Home/mastercertificate',
        name: 'Mastercertificate',
        component: () => import('../views/userSestem/Mastercertificate')
      },
      {
        meta: { title: '师傅登录日志' },
        path: '/Home/masterloginlog',
        name: 'Masterloginlog',
        component: () => import('../views/userSestem/Masterloginlog')
      },
      {
        meta: { title: '用户登录日志' },
        path: '/Home/memberloginlog',
        name: 'Memberloginlog',
        component: () => import('../views/userSestem/Memberloginlog')
      },
      {
        meta: { title: '员工管理' },
        path: '/Home/employee',
        name: 'Employee',
        component: () => import('../views/employee/Employee')
      },
      {
        meta: { title: '职务管理' },
        path: '/Home/position',
        name: 'Position',
        component: () => import('../views/employee/Position')
      },
      {
        meta: { title: '师傅管理' },
        path: '/Home/master',
        name: 'Master',
        component: () => import('../views/Masterworker.vue')
      },
      {
        meta: { title: '师傅部门管理' },
        path: '/Home/master_group',
        name: 'Master_group',
        component: () => import('../views/magserSestem/Master_group.vue')
      },
      {
        meta: { title: '师傅佣金规则' },
        path: '/Home/magserSestem/masterCom',
        name: 'MasterCom',
        component: () => import('../views/magserSestem/MasterCom.vue')
      },
      {
        meta: { title: '师傅等级' },
        path: '/Home/magserSestem/masterGrade',
        name: 'MasterGrade',
        component: () => import('../views/magserSestem/MasterGrade.vue')
      },
      {
        meta: { title: '师傅提现' },
        path: '/Home/mastercash',
        name: 'Mastercash',
        component: () => import('../views/Mastercash.vue')
      },
      {
        meta: { title: '用户提现' },
        path: '/Home/membercash',
        name: 'Membercash',
        component: () => import('../views/Membercash.vue')
      },

      {
        meta: { title: '师傅技能分类' },
        path: '/Home/master_skill',
        name: 'Master_skill',
        component: () => import('../views/subsidiary/Master_skill')
      },
      {
        meta: { title: '关于我们' },
        path: '/Home/about_us',
        name: 'About_us',
        component: () => import('../views/subsidiary/About_us')
      },
      {
        meta: { title: '服务协议' },
        path: '/Home/agreement',
        name: 'Agreement',
        component: () => import('../views/subsidiary/Agreement')
      },
      {
        meta: { title: '上门服务费' },
        path: '/Home/service_fee',
        name: 'Service_fee',
        component: () => import('../views/subsidiary/Service_fee')
      },
      {
        meta: { title: '菜单列表' },
        path: '/Home/menu',
        name: 'Menu',
        component: () => import('../views/system/Menu')
      },
      {
        meta: { title: '角色管理' },
        path: '/Home/role',
        name: 'Role',
        component: () => import('../views/system/Role')
      },
      {
        meta: { title: '管理员' },
        path: '/Home/register',
        name: 'Register',
        component: () => import('../views/system/Register')
      },
      {
        meta: { title: '系统设置' },
        path: '/Home/system/setting',
        name: 'Setting',
        component: () => import('../views/system/Setting')
      },
      {
        meta: { title: '扣费编码' },
        path: '/Home/code',
        name: 'Code',
        component: () => import('../views/deductionItem/Deduction_code')
      },
      {
        meta: { title: '扣费规则' },
        path: '/Home/deduction',
        name: 'Deduction',
        component: () => import('../views/Deduction')
      },
      {
        meta: { title: '押金管理' },
        path: '/Home/deposit',
        name: 'Deposit',
        component: () => import('../views/subsidiary/Deposit')
      },
      {
        meta: { title: '消息管理' },
        path: '/Home/information',
        name: 'Information',
        component: () => import('../views/Information')
      },
      {
        meta: { title: '版本管理' },
        path: '/Home/edition',
        name: 'Edition',
        component: () => import('../views/Edition')
      },
      {
        meta: { title: '版本审核管理' },
        path: '/Home/front/version',
        name: 'version',
        component: () => import('../views/front/Version')
      },
      {
        meta: { title: '业绩排行' },
        path: '/Home/front/statistics',
        name: 'Statistics',
        component: () => import('../views/front/Statistics')
      },
      {
        meta: { title: '广告统计' },
        path: '/Home/front/advDayList',
        name: 'AdvDayList',
        component: () => import('../views/front/advstic')
      },
      {
        meta: { title: '海报管理' },
        path: '/Home/poster',
        name: 'Poster',
        component: () => import('../views/subsidiary/Poster')
      },
      {
        meta: { title: '广告牌二维码管理' },
        path: '/Home/system/billboard',
        name: 'Billboard',
        component: () => import('../views/system/Billboard')
      },
    ],
  },
  {
    path: '/',
    redirect:'/login'
  }

]

const router = createRouter({
  mode:'history',
  history: createWebHashHistory(),
  routes
})

export default router
