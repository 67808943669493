//src/utils/request.js
import axios from 'axios'
import router from "@/router";

// 创建 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
    timeout: 100000, // request timeout
})

//  请求拦截器
service.interceptors.request.use((req) => {
        // console.log('请求来了',req)
        if(req.type){
            req.headers['Content-Type']=req.type
        }else {
            req.headers['Content-Type'] = 'application/json;charset=UTF-8'
        }
        req.headers['Accept'] = 'application/json'
        req.headers['Request-From'] = 'mobile'
        let token = localStorage.getItem('Token') || null
        if (token) {
            // 如果token不为null，否则传token给后台
            // req.headers['Authorization:'] = 'Bearer '+token
            req.headers['Authorization'] = token
        }
        return req
    },
    (error) => {
        return Promise.reject(error)
    }
)
// 响应拦截器
service.interceptors.response.use(
    (resp) => {
        //届时根据后端返回success或者code值判断
        if (resp.data.code==9001){
                console.log(1)
                localStorage.removeItem('Token')
                localStorage.removeItem('user')
                setTimeout(()=>{
                    router.push('/login')
                },2000)
                this.$message.error('身份信息已过期，请重新登陆！！')
        } else {
            return resp.data
        }
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default service